import { List } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import RecyclingBinCard from 'components/atoms/RecyclingBinCard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-use';
import { setIncludeDeletedData, setIncludeInactiveData, setReturnDeletedDataOnly } from 'redux/services/freddyCadabby/applicationsParams';
import { useGetApplicationsQuery } from 'redux/services/freddyCadabby/freddyCadabbyApi';
import { ReduxState } from 'redux/store';

export const RecyclingBinList = (): JSX.Element => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isRecyclingBin = pathname?.includes('recycling-bin');
  const { freddyCadabbyParams } = useSelector((state: ReduxState) => state);
  const { data: appData, isLoading, isFetching } = useGetApplicationsQuery(freddyCadabbyParams);

  // if it's the recycling bin page, set params
  try {
    if (isRecyclingBin) {
      dispatch(setIncludeDeletedData(true));
      dispatch(setReturnDeletedDataOnly(true));
      dispatch(setIncludeInactiveData(true));
    }
  } catch (err) {
    console.log(err);
  }
  /* ******************** Render ******************** */

  useEffect(() => {
    return (): void => {
      dispatch(setIncludeDeletedData(undefined));
      dispatch(setReturnDeletedDataOnly(undefined));
      dispatch(setIncludeInactiveData(false));
    };
  }, [dispatch]);

  return (
    <List
      grid={{ gutter: 16, column: 4 }}
      loading={{
        indicator: <BlockLoader direction="loader loader--slideUp" />,
        size: 'large',
        spinning: isLoading || isFetching
      }}
      dataSource={appData?.data}
      renderItem={(item): JSX.Element => <RecyclingBinCard application={item} />}
    />
  );
};
