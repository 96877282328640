import { AppstoreOutlined } from '@ant-design/icons';
import { Card, Col, List, message, Modal, Row, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoverApplicationMutation } from 'redux/services/freddyCadabby/freddyCadabbyApi';
import { InlineStylesModel } from '../../models/InlineStylesModel';

const styles: InlineStylesModel = {
  card: {
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center'
  },
  cardLink: {
    display: 'flex',
    justifyContent: 'center'
  },
  icons: {
    fontSize: 60,
    color: 'rgb(89, 93, 110)',
    marginBottom: 20,
    width: 60,
    background: '#1f1e2e',
    padding: 10,
    borderRadius: '50%'
  },
  meta: {
    fontSize: '1em',
    width: '100%',
    marginBottom: 5,
    color: 'rgb(116, 120, 141)'
  }
};

export const ApplicationCard = ({ application }: any): JSX.Element => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recoverApplication] = useRecoverApplicationMutation();

  /* ******************** Functions ******************** */
  const handleEdit = (): void => {
    if (application.isDeleted) {
      setIsModalOpen(!isModalOpen);
    } else {
      navigate(`applications/${application.id}`);
    }
  };

  const handleOk = async (): Promise<void> => {
    try {
      await recoverApplication({ applicationId: application.id as string }).unwrap();
      message.success('Application successfuly recovered');
    } catch (error) {
      console.log(error);
      message.error('Application failed to be recovered!');
    }
  };

  const handleExit = () => {
    setIsModalOpen(false);
  };

  /* ******************** Render ******************** */
  return (
    <List.Item>
      <Card hoverable style={styles.card} onClick={handleEdit}>
        {application.logoUrl ? <img alt="" src={application.logoUrl} style={styles.icons} /> : <AppstoreOutlined style={styles.icons} />}

        <Meta
          style={styles.meta}
          title={
            <Typography.Title level={4} ellipsis={{ rows: 1, tooltip: application.name }} style={{ whiteSpace: 'normal' }}>
              {application.name}
            </Typography.Title>
          }
          description={
            <Typography.Paragraph style={{ marginBottom: 0, color: 'rgb(116, 120, 141)' }} ellipsis={{ rows: 1, tooltip: application.description }}>
              {application.description ? application.description : 'No Description Available'}
            </Typography.Paragraph>
          }
        />
      </Card>
      <Modal visible={isModalOpen} onOk={handleOk} closable onCancel={handleExit}>
        <Row gutter={[32, 16]} align="middle" justify="center">
          <Col span={24}>
            <Typography>Do you wish to recover this Legal Entity?</Typography>
          </Col>
          <Col span={24}></Col>
        </Row>
      </Modal>
    </List.Item>
  );
};
