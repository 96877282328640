import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationParams } from 'models/Application';

const initialState: ApplicationParams = {
  includeDeletedData: undefined,
  includeInactiveData: true,
  returnDeletedDataOnly: undefined,
  orderByDirection: undefined,
  orderByField: undefined,
  applicationNameContains: undefined,
  wouldYouLikeToPlayAGame: undefined,
  isDivisionContextRequired: undefined,
  returnInactiveDataOnly: undefined
};

export const freddyCadabbyParams = createSlice({
  name: 'freddyCadabbyParams',
  initialState,
  reducers: {
    setIncludeDeletedData: (state, { payload }: PayloadAction<ApplicationParams['includeDeletedData']>) => {
      state.includeDeletedData = payload;
    },
    setIncludeInactiveData: (state, { payload }: PayloadAction<ApplicationParams['includeInactiveData']>) => {
      state.includeInactiveData = payload;
    },
    setReturnDeletedDataOnly: (state, { payload }: PayloadAction<ApplicationParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
    },
    setOrderByDirection: (state, { payload }: PayloadAction<ApplicationParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    setOrderByField: (state, { payload }: PayloadAction<ApplicationParams['orderByField']>) => {
      state.orderByField = payload;
    },
    setApplicationNameContains: (state, { payload }: PayloadAction<ApplicationParams['applicationNameContains']>) => {
      state.applicationNameContains = payload;
    },
    setWouldYouLikeToPlayAGame: (state, { payload }: PayloadAction<ApplicationParams['wouldYouLikeToPlayAGame']>) => {
      state.wouldYouLikeToPlayAGame = payload;
    },
    setIsDivisionContextRequired: (state, { payload }: PayloadAction<ApplicationParams['isDivisionContextRequired']>) => {
      state.isDivisionContextRequired = payload;
    },
    setReturnInactiveDataOnly: (state, { payload }: PayloadAction<ApplicationParams['returnInactiveDataOnly']>) => {
      state.returnInactiveDataOnly = payload;
    },
    setApplicationUrlEquals: (state, { payload }: PayloadAction<ApplicationParams['applicationUrlEquals']>) => {
      state.applicationUrlEquals = payload;
    }
  }
});

export const {
  setIncludeDeletedData,
  setIncludeInactiveData,
  setOrderByDirection,
  setApplicationNameContains,
  setReturnDeletedDataOnly,
  setOrderByField,
  setWouldYouLikeToPlayAGame,
  setIsDivisionContextRequired,
  setReturnInactiveDataOnly,
  setApplicationUrlEquals
} = freddyCadabbyParams.actions;
