import { Col, List, Row } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { DivisionApplicationListItem } from 'components/atoms/DivisionApplicationListItem';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { useParams } from 'react-router-dom';
import { useGetDivisionApplicationsQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';

export const DivisionApplicationList = ({ appName }: any): JSX.Element => {
  const { id: applicationId } = useParams();
  const applicationIdString = applicationId as string;

  const { data, isLoading, isFetching } = useGetDivisionApplicationsQuery(applicationIdString);
  return (
    <Row style={{ marginTop: 0 }}>
      <Col style={{ width: '100%', padding: 10, background: 'rgb(206, 213, 242)' }}>
        <Row align="middle" justify="space-between">
          {isLoading || isFetching ? <AntPageTitle text="Loading ..." /> : <AntPageTitle text={!data?.data.length ? 'No Divisions Found' : 'Enabled Divisions'} />}
        </Row>
      </Col>

      {data?.data.length !== 0 && (
        <Col span={24}>
          <List
            loading={{
              indicator: <BlockLoader direction="loader loader--slideUp" />,
              size: 'large',
              spinning: isLoading || isFetching
            }}
            grid={{ column: 3, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
            dataSource={data?.data}
            renderItem={(item): JSX.Element => <DivisionApplicationListItem division={item} />}
          />
        </Col>
      )}
    </Row>
  );
};
