import { Card, Col, Row, Typography } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';

const { Paragraph } = Typography;

const styles: InlineStylesModel = {
  wrapper: {
    height: '100%'
  },
  container: {
    padding: 2
  },
  title: {
    fontSize: '0.85em',
    color: 'green',
    marginBottom: 0
  },
  titleContainer: {
    paddingLeft: 10
  },
  iconContainer: {
    paddingLeft: 10
  },
  editButton: {
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 7
  },
  subTitle: {
    fontSize: '0.75em',
    marginBottom: 0
  }
};

export const DivisionApplicationListItem = ({ division }: any): JSX.Element => {
  /* ******************** Renderer ******************** */
  return (
    <Card bodyStyle={{ padding: 15 }} style={{ margin: '0 1px', marginBottom: 3 }}>
      <Row justify="space-between" align="middle">
        <Col span={18}>
          <Paragraph style={styles.title} ellipsis>
            {division.division.name}
          </Paragraph>
          <Paragraph style={styles.subTitle}>{division.division.erpId}</Paragraph>
        </Col>
      </Row>
    </Card>
  );
};
