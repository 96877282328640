import { List } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { ApplicationCard } from 'components/molecules/ApplicationCard';
import { useDispatch, useSelector } from 'react-redux';
import { useGetApplicationsQuery } from 'redux/services/freddyCadabby/freddyCadabbyApi';
import { ReduxState } from 'redux/store';

export const BusinessList = (): JSX.Element => {
  const { freddyCadabbyParams } = useSelector((state: ReduxState) => state);
  const { data: appData, isLoading, isFetching } = useGetApplicationsQuery(freddyCadabbyParams);
  const dispatch = useDispatch();

  /* ******************** Render ******************** */
  return (
    <List
      grid={{ gutter: 16, column: 4 }}
      loading={{
        indicator: <BlockLoader direction="loader loader--slideUp" />,
        size: 'large',
        spinning: isLoading || isFetching
      }}
      dataSource={appData?.data}
      renderItem={(item): JSX.Element => <ApplicationCard application={item} />}
    />
  );
};
