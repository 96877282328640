import { Form, Switch, SwitchProps } from 'antd';
import { useField } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';
interface Props extends SwitchProps {
  fieldName: string;
  label?: string;
}

export const SwitchInputIsActive: FC<Props> = ({ fieldName, label }) => {
  const [{ value }, { touched, error }, { setValue }] = useField<boolean>(fieldName);

  return (
    <Form.Item
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Switch checked={value} onChange={(checked): void => setValue(checked)} />
    </Form.Item>
  );
};
