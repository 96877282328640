import { Col, Row } from 'antd';
import { BusinessNameFilter } from 'components/atoms/Filters/BusinessNameFilter';
import { CustomerType } from 'components/atoms/Inputs/CustomerType';
import { StatusSearch } from 'components/atoms/Inputs/StatusSearchh';
import RecoverActions from 'components/atoms/RecoverActions';
import { Stack } from 'components/UI';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useGetApplicationsQuery } from 'redux/services/freddyCadabby/freddyCadabbyApi';

export const HomeControls: React.FC = () => {
  const { pathname } = useLocation();
  const isRecyclingBinPage = pathname.includes('/recycling-bin');
  const { data: deletedAppData } = useGetApplicationsQuery({ returnDeletedDataOnly: true, includeInactiveData: true });

  return (
    <>
      {!isRecyclingBinPage && (
        <Row>
          <Stack flexDirection="row" justifyContent="space-between" maxWidth={1920}>
            {!isRecyclingBinPage ?? (
              <Col style={{ paddingRight: 16 }}>
                <Stack justifyContent="start">
                  <AntPageTitle text={'Applications'} />
                </Stack>
              </Col>
            )}
            <Col>
              <Stack justifyContent="start">{!isRecyclingBinPage && <BusinessNameFilter />}</Stack>
            </Col>
            <Col>
              {/* <Stack justifyContent="start">{!isRecyclingBinPage && <IncludeInactiveDataOnly />}</Stack> */}
              <Stack justifyContent="start">{!isRecyclingBinPage && <StatusSearch />}</Stack>
            </Col>
            <Col>
              {/* <Stack justifyContent="start">{!isRecyclingBinPage && <IsDivisionContextRequiredFilter />}</Stack> */}
              <Stack justifyContent="start">{!isRecyclingBinPage && <CustomerType />}</Stack>
            </Col>
          </Stack>
        </Row>
      )}
      <Row>
        {isRecyclingBinPage && (
          <Row style={{ width: '100%', padding: 10, background: 'rgb(206, 213, 242)' }} align="middle" justify="space-between">
            <Col style={{ paddingRight: 16 }}>
              <Stack justifyContent="start">
                <AntPageTitle text={'Application Recycle Bin'} />
              </Stack>
            </Col>
            <Col>
              <Stack justifyContent="start">
                <RecoverActions deletedApplicationData={deletedAppData?.data} />
              </Stack>
            </Col>
          </Row>
        )}
      </Row>
    </>
  );
};
