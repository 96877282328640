import { Button, message, Modal, Row, Space, Spin, Typography } from 'antd';
import { Application } from 'models/Application';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useDeleteApplicationMutation, useRecoverApplicationMutation } from 'redux/services/freddyCadabby/freddyCadabbyApi';
import { setSelectedApplications } from 'redux/slices/selectedApplicationSlice';
import { ReduxState } from 'redux/store';
import { BlockLoader } from './BlockLoader';

interface Props {
  deletedApplicationData?: Application[] | undefined;
}

const RecoverActions = ({ deletedApplicationData }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const [showPurgeModal, _setShowPurgeModal] = useState(false);
  const [applicationCount, _setApplicationCount] = useState(1);
  const { selectedApplications } = useSelector((state: ReduxState) => state.selectedApplicationsSlice);

  const [recoverApplication, { isLoading: recoveringApplication }] = useRecoverApplicationMutation();
  const [purgeApplication, { isLoading: isPurgingApplication }] = useDeleteApplicationMutation();

  const totalSelectedApplications = selectedApplications.length;

  const handleRestoreSelectedRoles = async (): Promise<void> => {
    if (selectedApplications.length < 1) {
      message.error('No entity selected!');

      return;
    }

    for (const application of selectedApplications) {
      try {
        await recoverApplication({ applicationId: application.id }).unwrap();
      } catch {
        message.error(`Applications item ${application.id} failed to be recovered!`);
      }
    }

    dispatch(setSelectedApplications([]));
    message.success(`Applications(s) were successfully recovered!`);
  };

  const handlePurgeItems = async (): Promise<void> => {
    if (selectedApplications.length < 1) {
      message.error('No items have been selected to be deleted!');

      return;
    }
    for (const application of selectedApplications) {
      try {
        await purgeApplication({ applicationId: application.id, params: { wouldYouLikeToPlayAGame: true } }).unwrap();
        _setApplicationCount((prev) => prev + 1);
      } catch (error) {
        message.error(`Failed to delete Application ${application.id}`);
      }
    }

    // _setPurgeCount(1);
    message.success(`Purged Applications(s) successfully!`);
    _setShowPurgeModal(false);
    _setApplicationCount(1);
    dispatch(setSelectedApplications([]));
  };

  const handleSelectAll = (): void => {
    if (deletedApplicationData !== undefined) {
      dispatch(setSelectedApplications(deletedApplicationData));
    }
  };

  const handleClearAll = (): void => {
    dispatch(setSelectedApplications([]));
  };

  return (
    <Row justify="space-between">
      <Row justify="space-between">
        <Button style={{ marginRight: 5 }} onClick={() => naviagte('/')}>
          Back
        </Button>
      </Row>
      <Row justify="space-between">
        <Button disabled={deletedApplicationData?.length === 0} onClick={!totalSelectedApplications ? handleSelectAll : handleClearAll} style={{ marginRight: 5 }} type="primary">
          {!totalSelectedApplications ? 'Select All' : 'Clear All'}
        </Button>
      </Row>
      <Row justify="space-between">
        <Button
          onClick={handleRestoreSelectedRoles}
          style={totalSelectedApplications ? { marginRight: 5, background: '#4e937a', borderColor: '#4e937a', color: 'white' } : { marginRight: 5 }}
          loading={recoveringApplication}
          disabled={!totalSelectedApplications}>
          Restore Selected ({totalSelectedApplications})
        </Button>
        <Button onClick={(): void => _setShowPurgeModal(!showPurgeModal)} danger loading={isPurgingApplication} disabled={!totalSelectedApplications}>
          Purge Selected ({totalSelectedApplications})
        </Button>
      </Row>
      <Modal
        title="Are you sure?"
        okText={`Purge Items (${totalSelectedApplications})`}
        visible={showPurgeModal}
        onOk={handlePurgeItems}
        closable={!isPurgingApplication}
        maskClosable={!isPurgingApplication}
        onCancel={(): void => _setShowPurgeModal(false)}
        cancelButtonProps={isPurgingApplication ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
        okButtonProps={isPurgingApplication ? { style: { background: '#dc3545', pointerEvents: 'none', opacity: '.3', border: 'none' } } : { style: { background: '#dc3545', border: 'none' } }}>
        <Spin
          spinning={isPurgingApplication}
          indicator={
            <Space style={{ width: '100%', marginLeft: '-50%', marginTop: '-37%' }}>
              <BlockLoader direction="loader loader--slideUp" message={`Purging Application ${applicationCount} of ${totalSelectedApplications}...`} />
            </Space>
          }>
          <Typography.Text type="danger">IF YOU CONTINUE, YOU WILL NO LONGER BE ABLE TO RECOVER THE SELECTED SALES ORDERS.</Typography.Text>
        </Spin>
      </Modal>
    </Row>
  );
};

export default RecoverActions;
