import { Form, Modal, Radio, RadioChangeEvent, SelectProps } from 'antd';
import { useField } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetApplicationQuery } from 'redux/services/freddyCadabby/freddyCadabbyApi';
import { useGetDivisionApplicationsQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { ReduxState } from 'redux/store';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label?: string;
}

export const FormRadio = ({ fieldName, label }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue }] = useField<string | boolean>(fieldName);
  const [showModal, setShowModal] = useState(false);
  const { freddyCadabbyParams } = useSelector((state: ReduxState) => state);
  const { id: applicationId } = useParams();

  const { data: divisionApplications } = useGetDivisionApplicationsQuery(applicationId as string);
  const { data: applicationData } = useGetApplicationQuery(
    { applicationId: applicationId as string, params: freddyCadabbyParams },
    {
      skip: applicationId === undefined
    }
  );

  const handleChange = (event: RadioChangeEvent): void => {
    if (divisionApplications && event.target.value === false && divisionApplications?.totalCount > 0) {
      setShowModal(true);
    } else {
      setValue(event.target.value);
    }
  };

  const handleOk = (): void => {
    setValue(false);
    setShowModal(false);
  };

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Radio.Group value={value} defaultValue={applicationData?.isDivisionContextRequired} onChange={handleChange} style={{ borderRadius: 5, marginTop: 16 }} buttonStyle="solid">
        <Radio.Button value={false}>Internal</Radio.Button>
        <Radio.Button value={true}>Customer</Radio.Button>
      </Radio.Group>
      <Modal title="Are you sure?" onOk={handleOk} okButtonProps={{ danger: true }} okText="Confirm" visible={showModal} onCancel={(): void => setShowModal(false)}>
        Any customer assignments will be deleted when switching to an internal application and saving.
      </Modal>
    </Form.Item>
  );
};
