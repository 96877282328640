import { AppstoreOutlined, EditFilled, SaveOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Col, message, Modal, Row, Spin, Upload, UploadProps } from 'antd';
import { AntButton } from 'components/UI/AntButton';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useCallback, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { cookieMonsterApi, useGetUserQuery, useUpdateUserProfileImageMutation } from 'redux/services/cookieMonster/cookieMonsterApi';
import { useGetApplicationQuery, useUpdateApplicationLogoMutation } from 'redux/services/freddyCadabby/freddyCadabbyApi';
import { ReduxState } from 'redux/store';
import { getCroppedImg } from 'utils/canvasUtils';

const styles: InlineStylesModel = {
  cropContainer: {
    height: '60vh'
  },
  controls: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: '100%',
    transform: 'translateX(-50%)',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft: 10
  },
  title: {
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '100%',
    transform: 'translateX(-50%)',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 1,
    justifyContent: 'center'
  }
};

export const LogoUpload = (profileData: any) => {
  const [updateProfileImage, { isLoading: isDeleting }] = useUpdateUserProfileImageMutation();
  const [updateApplicationLogo, { isLoading: isAppLogoUpdating }] = useUpdateApplicationLogoMutation();

  const { user } = useAuth0();
  const { pathname } = useLocation();
  const isEditApplicaitonPage = pathname.includes('/applications/');

  const userId = user?.sub;

  const { data: userData, isLoading, isFetching } = useGetUserQuery(userId as string, { skip: !userId });

  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = React.useState('');
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);
  const [hover, setHover] = useState(false);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppedImage, setCroppedImage] = useState<string>('');

  function readFile(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }
  const { id: applicationId } = useParams();
  const { freddyCadabbyParams } = useSelector((state: ReduxState) => state);

  const { data: applicationData, isLoading: isAppDataLoading } = useGetApplicationQuery(
    { applicationId: applicationId as string, params: { ...freddyCadabbyParams, includeInactiveData: true } },
    {
      skip: applicationId === undefined
    }
  );

  // const handlePreviewCroppedImage = useCallback(async () => {
  //   try {
  //     const croppedImageSrc = await getCroppedImg(imageSrc, croppedAreaPixels);
  //     setCroppedImage(croppedImageSrc?.toDataURL('image/png') as any);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [croppedAreaPixels]);

  const handleCroppedImageUpload = useCallback(async () => {
    if (!userId) return message.error('No customer found, please refresh the page');
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      croppedImage?.toBlob((blob: BlobPart | any) => {
        const fileCropped = new File([blob], `logo${new Date().toISOString()}.jpeg`, { type: 'image/jpeg' });
        const formData = new FormData();
        formData.append('logo.jpeg', fileCropped);
        if (!isEditApplicaitonPage) {
          updateProfileImage({ userId, payload: formData });
        } else {
          if (!applicationId) return message.error('No application found, please refresh the page');
          updateApplicationLogo({ applicationId, payload: formData });
        }
      }, 'image/jpeg');
      message.success('Logo successfully changed');
    } catch (error) {
      message.error((error as { data: string }).data ?? 'Logo could not be changed at this time');
      console.error(error);
    }
  }, [userId, imageSrc, croppedAreaPixels, updateProfileImage]);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onOk = () => {
    try {
      setCroppedImage('');
      setImageSrc('');
      setShowModal(false);
      handleCroppedImageUpload();
      cookieMonsterApi.util.invalidateTags(['User']);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = () => {
    const emptyLogo = new FormData();
    emptyLogo.append('logo.jpeg', '');
    if (!applicationId || !userId) return message.error('No id found, please refresh the page.');
    isEditApplicaitonPage ? updateApplicationLogo({ applicationId, payload: emptyLogo }) : updateProfileImage({ userId, payload: emptyLogo });
  };

  // const onClose = useCallback(() => {
  //   setCroppedImage('null');
  // }, []);

  const handleCloseModal = () => {
    setCroppedImage('');
    setImageSrc('');
    setShowModal(false);
  };

  const validateSrc = () => {
    if (isEditApplicaitonPage) {
      return applicationData?.logoUrl ? applicationData?.logoUrl : undefined;
    } else if (userData && userData.user_metadata.profile_image_url) return userData.user_metadata.profile_image_url;
    else if ((!isLoading || !isFetching) && !isEditApplicaitonPage) return user?.picture;
  };

  const props: UploadProps = {
    name: 'file',
    async onChange(info: any) {
      setShowModal(true);
      if (info.file) {
        const file = info.file.originFileObj;
        let imageDataUrl = await readFile(file);

        setImageSrc(imageDataUrl as any);
      }
    }
  };

  return (
    <div style={{ paddingTop: 32 }}>
      {imageSrc ? (
        <React.Fragment>
          <Modal width={800} visible={showModal} onCancel={handleCloseModal} onOk={onOk}>
            <div style={styles.cropContainer}>
              <Cropper cropShape="round" showGrid={false} image={imageSrc} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
            </div>
            <div style={styles.controls}>
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value as any);
                }}
                className="zoom-range"
              />
            </div>
          </Modal>
        </React.Fragment>
      ) : (
        <>
          <Row align="middle">
            <Upload {...props}>
              <Col style={{ borderRadius: 50 }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <Avatar
                  icon={isLoading || isFetching ? <Spin spinning></Spin> : <AppstoreOutlined />}
                  style={{ cursor: 'pointer', filter: hover ? 'brightness(50%)' : '', transition: '0.25s ease-in-out', position: 'relative' }}
                  size={100}
                  alt=""
                  src={validateSrc()}
                />
                {hover && (
                  <Avatar
                    icon={<EditFilled />}
                    style={{ cursor: 'pointer', filter: hover ? 'brightness(60%)' : '', transition: '0.25s ease-in-out', position: 'absolute', left: 0, opacity: 0.65 }}
                    size={100}
                    alt=""
                  />
                )}
              </Col>
            </Upload>
            <Col>
              <AntButton
                style={{ marginLeft: 16 }}
                disabled={isEditApplicaitonPage ? !applicationData?.logoUrl : !userData?.user_metadata.profile_image_url}
                onClick={handleDelete}
                loading={isDeleting || isAppLogoUpdating}
                danger
                type="ghost"
                icon={<SaveOutlined />}>
                Delete Current Picture
              </AntButton>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
