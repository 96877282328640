import { Col, Form, Row } from 'antd';
import { SwitchInputIsActive, TextInput } from 'components/UI/FormItems';
import { SelectInput } from 'components/UI/FormItems/SelectInput';

export const CreateApplicationForm = (): JSX.Element => {
  const options = ['Applications', 'Internal Applications', 'Warehouse'].map((item) => ({ label: item, value: item }));
  // Hooks

  // Render
  return (
    <Form layout="vertical">
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <TextInput fieldName="name" label="Name" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="accessPermissionName" label="Access Permission Name" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="description" label="Description" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="url" label="URL" />
        </Col>
        <Col span={12}>
          <SelectInput fieldName="menuTab" label="Menu Tab" options={options} />
        </Col>
        <Col span={24}>
          <SwitchInputIsActive fieldName="isMobileOptimized" label="Is Mobile Optimized" />
        </Col>
        <Col span={24}>
          <SwitchInputIsActive fieldName="isActive" label="Is Active" />
        </Col>
      </Row>
    </Form>
  );
};
