import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Application } from 'models/Application';

interface InitialState {
  selectedApplications: Application[];
}

const initialState: InitialState = {
  selectedApplications: []
};

export const selectedApplicationsSlice = createSlice({
  name: 'selectedApplicationsSlice',
  initialState,
  reducers: {
    setSelectedApplications: (state, { payload }: PayloadAction<Application[]>) => {
      state.selectedApplications = payload;
    },
    addSelectedApplication: (state, { payload }: PayloadAction<Application>) => {
      state.selectedApplications = [...state.selectedApplications, payload];
    },
    removeSelectedApplication: (state, { payload }: PayloadAction<Application>) => {
      state.selectedApplications = state.selectedApplications.filter((item) => item.id !== payload.id);
    }
  }
});

export const { setSelectedApplications, addSelectedApplication, removeSelectedApplication } = selectedApplicationsSlice.actions;
