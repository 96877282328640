import { About } from 'pages/About';
import { CreateApplicationPage } from 'pages/CreateApplication';
import { EditAppllicationPage } from 'pages/EditApplication';
import { EditLayout } from 'pages/EditLayout';
import { EditProfilePage } from 'pages/EditProfile';
import { Forbidden } from 'pages/Forbidden';
import { HomePage } from 'pages/Home';
import { RecyclingBinPage } from 'pages/RecyclingBin';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/unauthorized" element={<Forbidden />} />
      <Route path="/about" element={<About />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/applications/create-application" element={<CreateApplicationPage />} />
      <Route path="/applications/recycling-bin" element={<RecyclingBinPage />} />
      <Route path="/applications/:id" element={<EditLayout />}>
        <Route index element={<EditAppllicationPage />} />
      </Route>
    </Routes>
  );
};
